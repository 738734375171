import React from 'react'

const NotFound = () => {
  return (
    <>
      Page not Found 
    </>
  )
}

export default NotFound
