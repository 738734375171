import React from 'react'

import PokemonChart from './PokemonChart'


const Main = () => {
  return (
    <>
      <PokemonChart />
    </>
  )
}

export default Main
